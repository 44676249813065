import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { Modal, Card, Button, ListGroup } from "react-bootstrap";
import axios from "axios";
import iziToast from "izitoast";
import { useAuth } from "../AuthContext";

const QuestionnaireEdit = () => {
    const { id } = useParams();
    const { apipath } = useAuth();

    const [optionDetails, setOptionDetails] = useState({});
    const [subheading, setSubheading] = useState("");
    const [currentSubheadingId, setCurrentSubheadingId] = useState(null);
    const [currentQuestionId, setCurrentQuestionId] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showSubHeadingDeleteModal, setShowSubHeadingDeleteModal] = useState(false);
    const [showAddQuestionModal, setShowAddQuestionModal] = useState(false); // For the Add Question modal
    const [questionText, setQuestionText] = useState(""); // Question input

    // Fetch option details
    const fetchOptionDetails = async () => {
        try {
            const response = await axios.get(`${apipath}/ques/get-questionnaires/${id}`);
            if (response.data.success) {
                setOptionDetails(response.data.data);
            } else {
                iziToast.error({
                    title: "Error",
                    message: "Failed to fetch option details",
                    position: "topCenter",
                });
            }
        } catch (error) {
            iziToast.error({
                title: "Error",
                message: "An error occurred while fetching details",
                position: "topCenter",
            });
        }
    };

    useEffect(() => {
        fetchOptionDetails();
    }, [id, apipath]);

    const handleAddSubheading = async () => {
        if (!subheading.trim()) {
            iziToast.error({
                title: "Error",
                message: "Subheading cannot be empty",
                position: "topCenter",
            });
            return;
        }

        try {
            // Build the updated structure with the new subheading
            const updatedQuestions = {
                subheading: { subheading }, // Add the new subheading without questions
            };

            // Make the PUT request to update the associated questions
            const response = await axios.put(`${apipath}/ques/edit-questionnaires/${id}`,
             updatedQuestions
            );

            if (response.data.success) {
                iziToast.success({
                    title: "Success",
                    message: "Subheading added successfully",
                    position: "topCenter",
                });
                setShowAddModal(false);
                setSubheading("");
                fetchOptionDetails();
            } else {
                iziToast.error({
                    title: "Error",
                    message: response.data.error || "Failed to add subheading",
                    position: "topCenter",
                });
            }
        } catch (error) {
            iziToast.error({
                title: "Error",
                message: "An error occurred while adding the subheading",
                position: "topCenter",
            });
        }
    };

    const handleAddQuestion = async () => {
        if (!questionText.trim() || !currentSubheadingId) {
            iziToast.error({
                title: "Error",
                message: "Question text or subheading ID missing",
                position: "topCenter",
            });
            return;
        }

        try {
            // Find the subheading to which the question will be added
            const currentSubheading = optionDetails.associatedQuestions.find(
                (sub) => sub._id === currentSubheadingId
            );

            if (!currentSubheading) {
                iziToast.error({
                    title: "Error",
                    message: "Subheading not found",
                    position: "topCenter",
                });
                return;
            }

            // Create the updated questions array
            const updatedQuestions = [
                ...(currentSubheading.questions || []), // Existing questions
                { questionText }, // New question
            ];

            // Make the PUT request to update the subheading's questions
            const response = await axios.put(
                `${apipath}/ques/edit-questionnaires/${id}?subheadingId=${currentSubheadingId}`,
                { questions: updatedQuestions } // Send the updated questions array
            );

            if (response.data.success) {
                iziToast.success({
                    title: "Success",
                    message: "Question added successfully",
                    position: "topCenter",
                });
                setQuestionText(""); // Reset the input
                fetchOptionDetails(); // Refresh the option details
            } else {
                iziToast.error({
                    title: "Error",
                    message: response.data.error || "Failed to add question",
                    position: "topCenter",
                });
            }
        } catch (error) {
            iziToast.error({
                title: "Error",
                message: "An error occurred while adding the question",
                position: "topCenter",
            });
        }
    };

    const handleDeleteSubheading = async () => {
        if (!currentSubheadingId) {
            iziToast.error({
                title: "Error",
                message: "Subheading ID is missing",
                position: "topCenter",
            });
            return;
        }

        try {
            const response = await axios.delete(
                `${apipath}/ques/delete-questionnaires/${id}?subheadingId=${currentSubheadingId}`
            );

            if (response.data.success) {
                iziToast.success({
                    title: "Success",
                    message: "Subheading deleted successfully",
                    position: "topCenter",
                });
                setShowSubHeadingDeleteModal(false);
                setCurrentSubheadingId(null);
                fetchOptionDetails();
            } else {
                iziToast.error({
                    title: "Error",
                    message: response.data.error || "Failed to delete subheading",
                    position: "topCenter",
                });
            }
        } catch (error) {
            iziToast.error({
                title: "Error",
                message: "An error occurred while deleting the subheading",
                position: "topCenter",
            });
        }
    };

    const handleDeleteQuestion = async () => {
        if (!currentQuestionId || !currentSubheadingId) {
            iziToast.error({
                title: "Error",
                message: "Required IDs are missing",
                position: "topCenter",
            });
            return;
        }

        try {
            const response = await axios.delete(
                `${apipath}/ques/delete-questionnaires/${id}?subheadingId=${currentSubheadingId}&questionId=${currentQuestionId}`
            );

            if (response.data.success) {
                iziToast.success({
                    title: "Success",
                    message: "Question deleted successfully",
                    position: "topCenter",
                });
                setShowDeleteModal(false);
                setCurrentQuestionId(null); // Reset the selected question ID
                fetchOptionDetails(); // Refresh the option details after deletion
            } else {
                iziToast.error({
                    title: "Error",
                    message: response.data.error || "Failed to delete the question",
                    position: "topCenter",
                });
            }
        } catch (error) {
            iziToast.error({
                title: "Error",
                message: "An error occurred while deleting the question",
                position: "topCenter",
            });
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />
            <section className="home">
                <div className="container mt-4">
                    <h3 className="mb-4">Edit Troubleshoot Option: {optionDetails.optionName}</h3>
                    <Button
                        variant="primary"
                        className="mb-3"
                        onClick={() => setShowAddModal(true)}
                    >
                        Add Subheading
                    </Button>

                    {optionDetails.associatedQuestions?.length > 0 ? (
                        <div className="row">
                            {optionDetails.associatedQuestions.map((sub) => (
                                <div className="col-md-6" key={sub._id}>
                                    <Card className="mb-4 shadow-sm">
                                        <Card.Header>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span>{sub.subheading}</span>
                                                <i
                                                    className="fa-solid fa-trash text-danger"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setCurrentSubheadingId(sub._id);
                                                        setShowSubHeadingDeleteModal(true);
                                                    }}
                                                    title="Delete Subheading"
                                                ></i>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <ListGroup variant="flush">
                                                {sub.questions.map((q) => (
                                                    <ListGroup.Item key={q._id} className="d-flex justify-content-between align-items-center">
                                                        <span>{q.questionText}</span>
                                                        <i
                                                            className="fa-solid fa-trash text-danger"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setCurrentQuestionId(q._id);
                                                                setCurrentSubheadingId(sub._id);
                                                                setShowDeleteModal(true);
                                                            }}
                                                            title="Delete Question"
                                                        ></i>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                            <Button
                                                variant="secondary"
                                                size="sm"
                                                className="mt-2"
                                                onClick={() => {
                                                    setCurrentSubheadingId(sub._id);
                                                    setShowAddQuestionModal(true); // Open Add Question modal
                                                }}
                                            >
                                                Add Question
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-muted">No subheadings or questions available.</p>
                    )}
                </div>
            </section>

            {/* Add Subheading Modal */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Subheading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter subheading"
                        value={subheading}
                        onChange={(e) => setSubheading(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleAddSubheading}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Add Question Modal */}
            <Modal show={showAddQuestionModal} onHide={() => setShowAddQuestionModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter question"
                        value={questionText}
                        onChange={(e) => setQuestionText(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddQuestionModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            handleAddQuestion();
                            setShowAddQuestionModal(false); // Close modal on success
                        }}
                    >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Subheading Confirmation Modal */}
            <Modal
                show={showSubHeadingDeleteModal}
                onHide={() => setShowSubHeadingDeleteModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this subheading?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSubHeadingDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteSubheading}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Question Confirmation Modal */}
            <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this question?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteQuestion}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default QuestionnaireEdit;
