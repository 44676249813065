import React, { useEffect, useState, useRef } from 'react';
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { getAllUsers, deleteUser } from '../userService';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import iziToast from "izitoast";

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const dataTableRef = useRef();
    const [show, setShow] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data = await getAllUsers();
                setUsers(data.user);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        if (!loading && users.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [loading, users]);

    const handleShow = (user) => {
        if (user.role === 'admin') {
            iziToast.error({
                message: "You cannot delete the Admin.",
                position: "topCenter"
            });
        } else {
            setUserToDelete(user._id);
            setShow(true);
        }
    };

    const handleClose = () => {
        setUserToDelete(null);
        setShow(false);
    };

    const handleDelete = async () => {
        try {
            await deleteUser(userToDelete);
            setUsers(users.filter(user => user._id !== userToDelete));
            handleClose();
        } catch (error) {
            setError(error);
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <>
            <SideBar />
            <Navbar />
            <section className='home'>
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className='bi bi-menu'></i>
                </div>

                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-content1">
                            <p>Are you sure you want to delete this user?</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="modal-footer-btn" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="modal-footer-btn" onClick={handleDelete}>
                            Delete
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="container">
                    <div className="row foot-tablerow">
                        <h5>User Details</h5>
                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <table id="datatable" className="table" ref={dataTableRef}>
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>User Role</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Joined On</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user, index) => (
                                            <tr key={user._id}>
                                                <td>{index + 1}</td>
                                                <td>{user.role}</td>
                                                <td>{user.username}</td>
                                                <td>{user.email}</td>
                                                <td>{user.phone}</td>
                                                <td>{formatDate(user.created_at)}</td>
                                                <td>
                                                    <Link to={`/user-details/${user._id}`} onClick={(e) => {
                                                        if (user.role === 'admin') {
                                                            e.preventDefault();
                                                            iziToast.error({
                                                                message: "The Admin is non-editable.",
                                                                position: "topCenter"
                                                            });
                                                        }
                                                    }}>
                                                        <i className="fa-regular fa-pen-to-square"></i>
                                                    </Link> &nbsp;
                                                    <i className="fa-solid fa-trash" onClick={() => handleShow(user)}></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UserList;
