import React, { useEffect, useRef } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';

const CourseEdit = () => {

    const dataTableRef = useRef();

    useEffect(() => {
        $(dataTableRef.current).DataTable();
    }, []);


    return (
        <>


            <SideBar />

            <section className='home'>
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className='bi bi-menu'></i>
                </div>



                <div className="container">
                    <div className="row foot-tablerow">
                        <h5>Edit Course Details</h5>
                        <div className="col-lg-11 maintable-column">
                            <div className="container mt-4">
                                <table id="datatable" className="table" ref={dataTableRef}>
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Course Name</th>
                                            <th>Overview</th>
                                            <th>Price</th>
                                            <th>Time Slot</th>
                                            <th>Instructor</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>SAFe® 3 Scrum Master</td>
                                            <td>Mauris et neque hendrerit</td>
                                            <td>30 April 2024</td>
                                            <td>Case Study</td>
                                            <td>Meiyappan Muthu</td>
                                            <td><i className="fa-regular fa-pen-to-square" ></i> &nbsp;<i
                                                className="fa-solid fa-trash"></i></td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>SAFe® 3 Scrum Master</td>
                                            <td>Mauris et neque hendrerit</td>
                                            <td>30 April 2024</td>
                                            <td>Case Study</td>
                                            <td>Meiyappan Muthu</td>
                                            <td><i className="fa-regular fa-pen-to-square"></i> &nbsp;<i
                                                className="fa-solid fa-trash"></i></td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>SAFe® 3 Scrum Master</td>
                                            <td>Mauris et neque hendrerit</td>
                                            <td>30 April 2024</td>
                                            <td>Case Study</td>
                                            <td>Meiyappan Muthu</td>
                                            <td><i className="fa-regular fa-pen-to-square"></i> &nbsp;<i
                                                className="fa-solid fa-trash"></i></td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>SAFe® 3 Scrum Master</td>
                                            <td>Mauris et neque hendrerit</td>
                                            <td>30 April 2024</td>
                                            <td>Case Study</td>
                                            <td>Meiyappan Muthu</td>
                                            <td><i className="fa-regular fa-pen-to-square"></i> &nbsp;<i
                                                className="fa-solid fa-trash"></i></td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>SAFe® 3 Scrum Master</td>
                                            <td>Mauris et neque hendrerit</td>
                                            <td>30 April 2024</td>
                                            <td>Case Study</td>
                                            <td>Meiyappan Muthu</td>
                                            <td><i className="fa-regular fa-pen-to-square"></i> &nbsp;<i
                                                className="fa-solid fa-trash"></i></td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>SAFe® 3 Scrum Master</td>
                                            <td>Mauris et neque hendrerit</td>
                                            <td>30 April 2024</td>
                                            <td>Case Study</td>
                                            <td>Meiyappan Muthu</td>
                                            <td><i className="fa-regular fa-pen-to-square"></i> &nbsp;<i
                                                className="fa-solid fa-trash"></i></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CourseEdit;