import React from "react";
import SideBar from "./SideBar";

const NavbarEdit = () => {
  return (
    <>
      <SideBar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div class="Container pt-3">
          <div class="row top-barcolumn">
            <h5>Edit Navbar Information</h5>
            <div class="col-lg-5 nav-column">
              <div>
                <form>
                  <div class="mb-3">
                    <label for="nav-items1" class="form-label">
                      Home
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Home"
                    />
                  </div>
                  <div class="mb-3">
                  <label for="nav-items2" class="form-label">
                      Courses
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Courses"
                    />
                  </div>
                  <div class="mb-3">
                  <label for="nav-items3" class="form-label">
                      BLogs
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item2"
                      aria-describedby="nav-item-edit"
                      placeholder="Blogs"
                    />
                  </div>
                  <div class="mb-3">
                  <label for="nav-items4" class="form-label">
                      Contact Us
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="nav-item4"
                      aria-describedby="nav-item-edit"
                      placeholder="Contact Us"
                    />
                  </div>
                  <button type="submit" className="btn btn-success">
                  Update
                </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NavbarEdit;
