import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../AuthContext";

const SideBar = () => {
  const [isActive, setIsActive] = useState(false);


  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const toggleSubmenu = (index) => {
    setActiveSubmenu(activeSubmenu === index ? null : index);
  };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const location = useLocation();

  const adminToken = localStorage.getItem('admintoken');

  const { adminLogout } = useAuth();
  if (!adminToken) {
    adminLogout();
  }

  const isTabActive = (path) => {
    return location.pathname === path ? 'activeTab' : '';
  };

  return (
    <>
      <div className={`sidebar ${isActive ? "close" : ""}`}>
        <Link className="logo-box text-decoration-none" onClick={toggleSidebar}>
          <i className="bi bi-menu-down"></i>
        </Link>

        <ul className="sidebar-list">
          <li>
            <div className="title">
              <Link to="/" className={`link ${isTabActive('/')}`}>
                <i className="bi bi-grid-3x3"></i>
                <span className="name">Dashboard</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/" className="link submenu-title">
                Dashboard
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/userlist" className={`link ${isTabActive('/userlist')}`}>
                <i className="bi bi-person"></i>
                <span className="name">Users</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Users</Link>
            </div>
          </li>
          <li className={`dropdown ${activeSubmenu === "general" ? "active" : ""
            }`}
            onClick={() => {
              toggleSubmenu("general");
            }}>
            <div className="title">
              <Link className="link">
              <i class="bi bi-database-check"></i>
                <span className="name">Master Data</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Master Data</Link>
              <Link to="/service-list" className="link">Service List</Link>
              <Link to="/car-list" className="link">Car Brand</Link>
              <Link to="/car-data" className="link">Car Model</Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link to="/questionnaries-list" className={`link ${isTabActive('/userlist')}`}>
              <i class="bi bi-patch-question"></i>
                <span className="name">Questionnaries</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Questionnaries</Link>
            </div>
          </li>

          {/* <li
            className={`dropdown ${activeSubmenu === "general" ? "active" : ""
              }`}
            onClick={() => {
              toggleSubmenu("general");
            }}
          >
            <div className="title">
              <Link className="link">
                <i className="bi bi-gear"></i>
                <span className="name">General Settings</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">General Settings</Link>
              <Link to="/logo" className="link">Logo</Link>
              <Link to="/favicon" className="link">FavIcon</Link>
              <Link to="/navedit" className="link">Navbar</Link>
              <Link to="/banners" className="link">Banners</Link>
              <Link to="/footeredit" className="link">Footer</Link>
            </div>
          </li> */}

          {/* <li className={`dropdown ${activeSubmenu === "page" ? "active" : ""
            }`}
            onClick={() => {
              toggleSubmenu("page");
            }}>
            <div className="title">
              <Link className="link">
                <i className="bi bi-bookshelf"></i>
                <span className="name">Page Settings</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Page Settings</Link>
              <Link to="/homeedit" className="link">Home</Link>
              <Link to="/aboutedit" className="link">About</Link>
              <Link to="/serviceedit" className="link">Service</Link>
              <Link to="/termsedit" className="link">Terms & Conditions</Link>
            </div>
          </li> */}

          {/* <li className={`dropdown ${activeSubmenu === "details" ? "active" : ""
            }`}
            onClick={() => {
              toggleSubmenu("details");
            }}>
            <div className="title">
              <Link className="link">
                <i className="bi bi-gear"></i>
                <span className="name">Details Section</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Details Section</Link>
              <Link to="/detailsedit" className="link">Content</Link>
            </div>
          </li> */}
          <li className={`dropdown ${activeSubmenu === "footer" ? "active" : ""
            }`}
            onClick={() => {
              toggleSubmenu("footer");
            }}>
            <div className="title">
              <Link className="link">
                <i className="bi bi-bookshelf"></i>
                <span className="name">Footer Section</span>
              </Link>
              <i className="bi bi-chevron-down"></i>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Footer Section</Link>
              <Link to="/contactedit" className="link">Contact</Link>
              <Link to="/footerCedit" className="link">Terms Use</Link>
              <Link to="/footerCedit" className="link">Privacy Policy</Link>
            </div>
          </li>
          {/* <li>
            <div className="title">
              <Link to="/profileedit" className="link">
                <i className="bi bi-person"></i>
                <span className="name">Profile</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Profile</Link>
            </div>
          </li> */}
          <li className="logout">
            <div className="title">
              <Link to="" className="link">
                <i className="bi bi-box-arrow-left"></i>
                <span className="name" onClick={adminLogout}>Logout</span>
              </Link>
            </div>

          </li>
        </ul>
      </div>
    </>
  );
};

export default SideBar;
