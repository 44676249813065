import axios from 'axios';

const API_URL = 'https://node.dgtlinnovations.in:4000/api/v1';
// const API_URL = 'http://localhost:4000/api/v1';

export const getAllUsers = async () => {
  const response = await axios.get(`${API_URL}/users/details`);
  return response.data;
};

export const getUserDetails = async (userId) => {
  const response = await axios.get(`${API_URL}/users/details/${userId}`);
  return response.data;
};

export const updateUser = async (userId, formData) => {
  const response = await axios.put(`${API_URL}/users/details/${userId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  console.log('Update response:', response.data);
  return response.data;
};

export const deleteUser = async (userId) => {
  const response = await axios.delete(`${API_URL}/users/details/${userId}`);
  return response.data;
};
