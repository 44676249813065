import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import SideBar from "./SideBar";
// import Navbar from "./Navbar";
import iziToast from "izitoast";
import $ from 'jquery';
import { useAuth } from "../AuthContext";

const CarData = () => {
  const dataTableRef = useRef();
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(""); // For dropdown
  const [brandName, setBrandName] = useState("");
  const [carModels, setCarModels] = useState("");
  const [loading, setLoading] = useState(false);

  const { apipath } = useAuth();

  // Fetch existing brands
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(`${apipath}/carlist/getall`); // Adjust API path accordingly
        if (Array.isArray(response.data)) {
          setBrands(response.data); // Ensure response is an array
        } else {
          setBrands([]); // If it's not an array, set an empty array
          iziToast.error({
            title: "Error",
            message: "Unexpected API response format.",
            position: "topCenter",
          });
        }
      } catch (error) {
        console.error("Error fetching car brands:", error);
        iziToast.error({
          title: "Error",
          message: "Failed to fetch car brands.",
          position: "topCenter",
        });
      }
    };

    fetchBrands();
  }, [apipath]);

  useEffect(() => {
    if (!loading && brands.length > 0) {
      $(dataTableRef.current).DataTable();
    }
  }, [loading, brands]);

  const handleAddBrandOrModel = async (e) => {
    e.preventDefault();
    setLoading(true);

    // If user selects an existing brand, update that brand with new models
    let finalBrand = selectedBrand ? selectedBrand : brandName;

    const modelsArray = carModels.includes(",")
      ? carModels.split(",").map((model) => model.trim())
      : [carModels.trim()];

    const formData = {
      brand: finalBrand,
      models: modelsArray,
    };

    try {
      const response = await axios.post(`${apipath}/carlist/add`, formData);
      if (Array.isArray(response.data)) {
        setBrands(response.data); // Ensure response is an array
      }
      setBrandName("");
      setCarModels("");
      setSelectedBrand(""); // Reset selected brand
      iziToast.success({
        title: "Success",
        message: "Car brand/model added successfully.",
        position: "topCenter",
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        iziToast.error({
          title: "Error",
          message: error.response.data.message,
          position: "topCenter",
        });
      } else {
        iziToast.error({
          title: "Error",
          message: "An error occurred while adding the car brand or model.",
          position: "topCenter",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteModel = async (brand, model) => {
    if (!window.confirm(`Are you sure you want to delete ${model} from ${brand}?`)) {
      return;
    }
    try {
      await axios.delete(`${apipath}/carlist/car-brands/${brand}/models/${model}`);
      setBrands((prevBrands) =>
        prevBrands.map((b) =>
          b.brand === brand
            ? { ...b, models: b.models.filter((m) => m !== model) }
            : b
        )
      );
      iziToast.success({
        title: "Success",
        message: `${model} deleted successfully from ${brand}.`,
        position: "topCenter",
      });
    } catch (error) {
      iziToast.error({
        title: "Error",
        message: "Failed to delete model. Please try again later.",
        position: "topCenter",
      });
    }
  };

  return (
    <>
      <SideBar />
      

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="container pt-3">
          <div className="row top-barcolumn">
              <h5>Add Car Model</h5>

            <div className="row nav-column">
              <form className="row" onSubmit={handleAddBrandOrModel}>
                <div className="col-lg-5">
                  <select
                    className="form-control"
                    id="brand-dropdown"
                    value={selectedBrand}
                    onChange={(e) => {
                      setSelectedBrand(e.target.value);
                      setBrandName(""); // Clear new brand name if selecting an existing brand
                    }}
                  >
                    <option value="">-- Select from Existing Brand --</option>
                    {brands.map((brand) => (
                      <option key={brand._id} value={brand.brand}>
                        {brand.brand}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-5">
                  <input
                    type="text"
                    className="form-control"
                    id="car-models"
                    value={carModels}
                    onChange={(e) => setCarModels(e.target.value)}
                    placeholder="Enter models (comma separated or single model)"
                    required
                  />
                </div>

                <div className="col-lg-2">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={loading}
                  >
                    {loading ? "Adding..." : "Add Car Model"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

          {/* Add a list of all brands and models */}
          {/* <div className="container"> */}
          <div className="row nav-column container">
            <div className="foot-tablerow">
              <h5 className="ms-1">Model List</h5>
              <div className="col-lg-12 maintable-column">
                <div className="container mt-4 overflow-auto">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr>
                        <th className="text-center col-2">S.No.</th>
                        <th className="text-center col-4">Car Brand</th>
                        <th className="text-center col-4">Car Model</th>
                        <th className="text-center col-2">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {(() => {
                        let serialIndex = 1; // Initialize serial index outside of both maps
                        return brands.map((brand) =>
                          brand.models.map((model, modelIndex) => (
                            <tr key={`${brand._id}-${modelIndex}`}>
                              <td className="text-center">{serialIndex++}</td> {/* Increment the serial index for each row */}
                              <td className="text-center">{brand.brand}</td> {/* Car brand */}
                              <td className="text-center">{model}</td> {/* Each car model */}
                              <td className=" d-flex align-items-center justify-content-center">
                                <i
                                  className="fa-solid fa-trash"
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() => handleDeleteModel(brand.brand, model)}
                                ></i>
                              </td>
                            </tr>
                          ))
                        );
                      })()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            </div>
          {/* </div> */}
      </section>
    </>
  );
};

export default CarData;