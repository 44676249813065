import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getUserDetails, updateUser } from '../userService';
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";

const UserDetailsEdit = () => {
  const { filepath } = useAuth();
  const { userId } = useParams();
  const [user, setUser] = useState({
    userType: '',
    username: '',
    email: '',
    phone: '',
    carbrand: '',
    carmodel: '',
    motortype: '',
    class_car: '',
    cylinders: '',
    geartype: '',
    manufacture_year: '',
    fueltype: '',
    car_image: null,  // For file upload
    car_registerdoc: null,  // For file upload
    shopname: '',
    uploadtradelicense: null,  // For file upload
    speciality: '',
    openingtime: '',
    closingtime: '',
    shopdescription: '',
    coverphoto: null,  // For file upload
    teamlist: [],
    servicelist: [],
    shoplocation: {
      address: '',
      longitude: '',
      latitude: '',
    }
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { user } = await getUserDetails(userId);
        setUser(prevUser => ({
          ...prevUser,
          ...user,
          shoplocation: user.shoplocation || {
            address: '',
            longitude: '',
            latitude: '',
          }
        }));
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchUserDetails();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name.startsWith('shoplocation.')) {
      const [key] = name.split('.').slice(1);
      setUser(prevUser => ({
        ...prevUser,
        shoplocation: {
          ...prevUser.shoplocation,
          [key]: value,
        },
      }));
    } else if (name.startsWith('teamlist.')) {
      const [index, field] = name.split('.').slice(1);
      const newTeamList = [...user.teamlist];
      newTeamList[index] = { ...newTeamList[index], [field]: value };
      setUser(prevUser => ({
        ...prevUser,
        teamlist: newTeamList,
      }));
    } else if (name.startsWith('servicelist.')) {
      const [index, field] = name.split('.').slice(1);
      const newServiceList = [...user.servicelist];
      newServiceList[index] = { ...newServiceList[index], [field]: value };
      setUser(prevUser => ({
        ...prevUser,
        servicelist: newServiceList,
      }));
    } else if (files) {
      setUser(prevUser => ({
        ...prevUser,
        [name]: files[0],
      }));
    } else {
      setUser(prevUser => ({
        ...prevUser,
        [name]: value,
      }));
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const formData = new FormData();
  //   Object.keys(user).forEach(key => {
  //     if (key === 'teamlist' || key === 'servicelist') {
  //       formData.append(key, JSON.stringify(user[key]));
  //     } else if (key === 'shoplocation') {
  //       formData.append(key, JSON.stringify(user[key]));
  //     } else {
  //       formData.append(key, user[key]);
  //     }
  //   });

  //   for (let pair of formData.entries()) {
  //     console.log(`${pair[0]}, ${pair[1]}`);
  //   }

  //   try {
  //     await updateUser(id, formData);
  //     alert('User updated successfully');
  //   } catch (error) {
  //     console.error('Error updating user:', error);
  //     alert('Error updating user');
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    Object.keys(user).forEach((key) => {
      if (key === 'teamlist' || key === 'servicelist') {
        formData.append(key, JSON.stringify(user[key]));
      } else if (key === 'shoplocation') {
        formData.append(key, JSON.stringify(user[key]));
      } else if (user[key]) {  // Only append fields that are non-null
        formData.append(key, user[key]);
      }
    });
  
    // Log FormData entries
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
  
    try {
      await updateUser(userId, formData);
      alert('User updated successfully');
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Error updating user');
    }
  };  

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit User Information</h5>
            <div className="row nav-column">
              <div>
                <form className="row" onSubmit={handleSubmit}>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="username" className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      name="username"
                      value={user.username || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      name="email"
                      value={user.email || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="phone" className="form-label">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      name="phone"
                      value={user.phone || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="created_at" className="form-label">Joined</label>
                    <input
                      type="text"
                      className="form-control"
                      id="created_at"
                      name="created_at"
                      value={user.created_at ? new Date(user.created_at).toLocaleDateString() : ''}
                      readOnly
                    />
                  </div>

                  {/* Add more fields based on userType */}
                  {user.userType === 'user' && (
                    <>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="carbrand" className="form-label">Car Brand</label>
                        <input
                          type="text"
                          className="form-control"
                          id="carbrand"
                          name="carbrand"
                          value={user.carbrand || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="carmodel" className="form-label">Car Model</label>
                        <input
                          type="text"
                          className="form-control"
                          id="carmodel"
                          name="carmodel"
                          value={user.carmodel || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="motortype" className="form-label">Motor Type</label>
                        <input
                          type="text"
                          className="form-control"
                          id="motortype"
                          name="motortype"
                          value={user.motortype || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="class_car" className="form-label">Class Car</label>
                        <input
                          type="text"
                          className="form-control"
                          id="class_car"
                          name="class_car"
                          value={user.class_car || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="cylinders" className="form-label">Cylinders</label>
                        <input
                          type="number"
                          className="form-control"
                          id="cylinders"
                          name="cylinders"
                          value={user.cylinders || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="geartype" className="form-label">Gear Type</label>
                        <input
                          type="text"
                          className="form-control"
                          id="geartype"
                          name="geartype"
                          value={user.geartype || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="manufacture_year" className="form-label">Manufacture Year</label>
                        <input
                          type="number"
                          className="form-control"
                          id="manufacture_year"
                          name="manufacture_year"
                          value={user.manufacture_year || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="fueltype" className="form-label">Fuel Type</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fueltype"
                          name="fueltype"
                          value={user.fueltype || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="car_image" className="form-label">Car Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="car_image"
                          name="car_image"
                          onChange={handleChange}
                        />
                        {user.car_image && (
                          <img
                            src={`${filepath}/images/${user.car_image}`}
                            alt={user.car_image}
                            style={{ width: '150px', height: 'auto', marginTop: '10px' }}
                          />
                        )}
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="car_registerdoc" className="form-label">Car Registration Document</label>
                        <input
                          type="file"
                          className="form-control"
                          id="car_registerdoc"
                          name="car_registerdoc"
                          onChange={handleChange}
                        />
                        {user.car_registerdoc && user.car_registerdoc && (
                          <div className="text-muted">
                            Existing file: {user.car_registerdoc}&nbsp;&nbsp;
                            <Link
                              to={`${filepath}/documents/${user.car_registerdoc}`}
                              style={{ color: 'inherit', textDecoration: 'none' }}
                            >
                              <i className="fa-regular fa-eye"></i>
                            </Link>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {user.userType === 'shopkeeper' && (
                    <>

                      <div className="col-lg-6 mb-3">
                        <label htmlFor="shopname" className="form-label">Shop Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="shopname"
                          name="shopname"
                          value={user.shopname || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="uploadtradelicense" className="form-label">Upload Trade License</label>
                        <input
                          type="file"
                          className="form-control"
                          id="uploadtradelicense"
                          name="uploadtradelicense"
                          onChange={handleChange}
                        />
                        {user.uploadtradelicense && user.uploadtradelicense && (
                          <div className="text-muted">
                            Existing file: {user.uploadtradelicense}&nbsp;&nbsp;
                            <Link
                              to={`${filepath}/documents/${user.uploadtradelicense}`}
                              style={{ color: 'inherit', textDecoration: 'none' }}
                            >
                              <i className="fa-regular fa-eye"></i>
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="speciality" className="form-label">Speciality</label>
                        <input
                          type="text"
                          className="form-control"
                          id="speciality"
                          name="speciality"
                          value={user.speciality || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="openingtime" className="form-label">Opening Time</label>
                        <input
                          type="text"
                          className="form-control"
                          id="openingtime"
                          name="openingtime"
                          value={user.openingtime || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="closingtime" className="form-label">Closing Time</label>
                        <input
                          type="text"
                          className="form-control"
                          id="closingtime"
                          name="closingtime"
                          value={user.closingtime || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="shopdescription" className="form-label">Shop Description</label>
                        <textarea
                          className="form-control"
                          id="shopdescription"
                          name="shopdescription"
                          value={user.shopdescription || ''}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="coverphoto" className="form-label">Cover Photo</label>
                        <input
                          type="file"
                          className="form-control"
                          id="coverphoto"
                          name="coverphoto"
                          onChange={handleChange}
                        />
                        {/* {user.coverphoto && user.coverphoto && (
                          <div className="text-muted">Existing image: {user.coverphoto}</div>
                        )} */}
                        {user.coverphoto && (
                          <img
                            src={`${filepath}/images/${user.coverphoto}`}
                            alt={user.coverphoto}
                            style={{ width: '150px', height: 'auto', marginTop: '10px' }}
                          />
                        )}
                      </div>

                      {/* Shop Location */}
                      <div className="col-12 mb-3">
                        <label htmlFor="shoplocation.address" className="form-label">Shop Address</label>
                        <input
                          type="text"
                          className="form-control"
                          id="shoplocation.address"
                          name="shoplocation.address"
                          value={user.shoplocation.address || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="shoplocation.longitude" className="form-label">Longitude</label>
                        <input
                          type="text"
                          className="form-control"
                          id="shoplocation.longitude"
                          name="shoplocation.longitude"
                          value={user.shoplocation.longitude || ''}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label htmlFor="shoplocation.latitude" className="form-label">Latitude</label>
                        <input
                          type="text"
                          className="form-control"
                          id="shoplocation.latitude"
                          name="shoplocation.latitude"
                          value={user.shoplocation.latitude || ''}
                          onChange={handleChange}
                        />
                      </div>

                      <div>
                        <h3>Team List</h3>
                        <div className="row">
                          {user.teamlist.map((team, index) => (
                            <div className="col-lg-6 mb-3" key={index}>
                              <div className="form-group">
                                <label htmlFor={`teamlist.${index}.membername`} className="form-label">Member Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={`teamlist.${index}.membername`}
                                  name={`teamlist.${index}.membername`}
                                  value={team.membername || ''}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor={`teamlist.${index}.memberdesignation`} className="form-label">Member Designation</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={`teamlist.${index}.memberdesignation`}
                                  name={`teamlist.${index}.memberdesignation`}
                                  value={team.memberdesignation || ''}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor={`teamlist.${index}.memberrating`} className="form-label">Member Rating</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={`teamlist.${index}.memberrating`}
                                  name={`teamlist.${index}.memberrating`}
                                  value={team.memberrating || ''}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor={`teamlist.${index}.memberjoindate`} className="form-label">Member Join Date</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={`teamlist.${index}.memberjoindate`}
                                  name={`teamlist.${index}.memberjoindate`}
                                  value={team.memberjoindate || ''}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor={`teamlist.${index}.memberdescription`} className="form-label">Team Member Description</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={`teamlist.${index}.memberdescription`}
                                  name={`teamlist.${index}.memberdescription`}
                                  value={team.memberdescription || ''}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor={`teamlist.${index}.memberimage`} className="form-label">Team Member Image</label>
                                <input
                                  type="file"
                                  className="form-control"
                                  id={`teamlist.${index}.memberimage`}
                                  name={`teamlist.${index}.memberimage`}
                                  onChange={handleChange}
                                />
                                {team.memberimage && (
                                  <img
                                    src={`${filepath}/images/${team.memberimage}`}
                                    alt={team.memberimage}
                                    style={{ width: '150px', height: 'auto', marginTop: '10px' }}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div>
                        <h3>Service List</h3>
                        {/* Dynamic Service List */}
                        <div className="row">
                          {user.servicelist.map((service, index) => (
                            <div className="col-lg-6 mb-3" key={index}>
                              <label htmlFor={`servicelist.${index}.servicename`} className="form-label">Service Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id={`servicelist.${index}.servicename`}
                                name={`servicelist.${index}.servicename`}
                                value={service.servicename || ''}
                                onChange={handleChange}
                              />
                              <label htmlFor={`servicelist.${index}.servicecategory`} className="form-label">Service Category</label>
                              <input
                                type="text"
                                className="form-control"
                                id={`servicelist.${index}.servicecategory`}
                                name={`servicelist.${index}.servicecategory`}
                                value={service.servicecategory || ''}
                                onChange={handleChange}
                              />
                              <label htmlFor={`servicelist.${index}.serviceprice`} className="form-label">Service Price</label>
                              <input
                                type="text"
                                className="form-control"
                                id={`servicelist.${index}.serviceprice`}
                                name={`servicelist.${index}.serviceprice`}
                                value={service.serviceprice || ''}
                                onChange={handleChange}
                              />
                              <label htmlFor={`servicelist.${index}.servicedescription`} className="form-label">Service Description</label>
                              <textarea
                                className="form-control"
                                id={`servicelist.${index}.servicedescription`}
                                name={`servicelist.${index}.servicedescription`}
                                value={service.servicedescription || ''}
                                onChange={handleChange}
                              ></textarea>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}

                  {/* Submit Button */}
                  <div className="col-lg-12 text-right">
                    <button type="submit" className="btn btn-primary">Update User</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserDetailsEdit;
