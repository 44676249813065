import React, { useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useLocation } from "react-router-dom";

const AdminProfile = () => {
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(!edit);
  };

  const location = useLocation();

  const adminname = localStorage.getItem('adminname');
  const adminemail = localStorage.getItem('adminemail');

  const isTabActive = (path) => {
    return location.pathname === path ? 'activeTab' : '';
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container">
          {/* <div className="col-lg-6 mx-auto image-header">
            <h1>Your Profile</h1>
          </div> */}
          <div className="col-lg-10 mx-auto congrats profile">
            <div className="d-flex justify-content-between align-items-center profile-header">
              <div className="profile-icon" onClick={isTabActive}>
                <div>
                  <img src="imgs/user-icon.png" alt="" />
                </div>
                <div className="mx-4">
                  <h5>{adminname}</h5>
                  <p>{adminemail}</p>
                </div>
              </div>
              <div>
                <span onClick={handleEdit} className="hand-icon">
                  Edit
                </span>
              </div>
            </div>
            <h5>Personal Information</h5>
            {edit ? (
              <div>
                <form className="row mt-3">
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>First Name</label>
                      <input
                        type="text"
                        placeholder="First Name"
                        className="form-control bg-transparent"
                        name="name"
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>Last Name</label>
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="form-control bg-transparent"
                        name="lastName"
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>Email</label>
                      <input
                        type="email"
                        placeholder="Enter Email"
                        className="form-control bg-transparent"
                        name="email"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>Phone</label>
                      <input
                        type="text"
                        placeholder="Phone"
                        className="form-control bg-transparent"
                        name="phone"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>Address</label>
                      <input
                        type="text"
                        placeholder="Address"
                        className="form-control bg-transparent"
                        name="address"
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>City</label>
                      <input
                        type="text"
                        placeholder="City"
                        className="form-control bg-transparent"
                        name="city"
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>State</label>
                      <input
                        type="text"
                        placeholder="State"
                        className="form-control bg-transparent"
                        name="state"
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>Country</label>
                      <input
                        type="text"
                        placeholder="Country"
                        className="form-control bg-transparent"
                        name="country"
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>Total Orders</label>
                      <input
                        type="text"
                        placeholder="Total Orders"
                        className="form-control bg-transparent"
                        name="totalOrders"
                        maxLength="100"
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <label>DOB</label>
                      <input
                        type="date"
                        placeholder="DOB"
                        className="form-control bg-transparent"
                        name="dob"
                        maxLength="15"
                      />
                    </div>
                  </div>
                  <div className="congrats-button mt-4">
                    <button>Update</button>
                  </div>
                </form>
              </div>
            ) : (
              <div className="row mt-3">
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>First Name</p>
                    <p></p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Last Name</p>
                    <p></p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Email</p>
                    <p></p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Phone</p>
                    <p></p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Address</p>
                    <p></p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>City</p>
                    <p></p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>State</p>
                    <p></p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Country</p>
                    <p></p>
                  </div>
                </div>
                {/* <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Total Orders</p>
                    <p>8</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>DOB</p>
                    <p>23 June 1990</p>
                  </div>
                </div> */}
              </div>
            )}
          </div>
          {/* <div className="congrats-button mb-5">
          <Link to={`/orders/${userId}`}>
            <button>Check Orders</button>
          </Link>
        </div> */}
        </div>
      </section>
    </>
  );
};

export default AdminProfile;
