import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import SideBar from "./SideBar";
// import Navbar from "./Navbar";
import iziToast from "izitoast";
import $ from 'jquery';
import { Modal } from "react-bootstrap";
import { useAuth } from "../AuthContext";

const CarBrand = () => {
    const dataTableRef = useRef();
    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(""); // For dropdown
    const [brandName, setBrandName] = useState("");
    const [brandZone, setBrandZone] = useState(""); // Added for brandzone dropdown
    const [carModels, setCarModels] = useState("01"); // Set default model as "01"
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [brandToDelete, setBrandToDelete] = useState(null);

    const { apipath } = useAuth();

    // Fetch existing brands
    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axios.get(`${apipath}/carlist/getall`); // Adjust API path accordingly
                if (Array.isArray(response.data)) {
                    setBrands(response.data); // Ensure response is an array
                } else {
                    setBrands([]); // If it's not an array, set an empty array
                    iziToast.error({
                        title: "Error",
                        message: "Unexpected API response format.",
                        position: "topCenter",
                    });
                }
            } catch (error) {
                console.error("Error fetching car brands:", error);
                iziToast.error({
                    title: "Error",
                    message: "Failed to fetch car brands.",
                    position: "topCenter",
                });
            }
        };

        fetchBrands();
    }, [apipath]);

    useEffect(() => {
        if (!loading && brands.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [loading, brands]);

    const handleAddBrandOrModel = async (e) => {
        e.preventDefault();
        setLoading(true);

        // If user selects an existing brand, update that brand with new models
        let finalBrand = selectedBrand ? selectedBrand : brandName;

        const modelsArray = [carModels]; // Default to "01"

        const formData = {
            brandzone: brandZone, // Pass selected brandzone
            brand: finalBrand,
            models: modelsArray,
        };

        try {
            const response = await axios.post(`${apipath}/carlist/add`, formData);
            if (Array.isArray(response.data)) {
                setBrands(response.data); // Ensure response is an array
            }
            setBrandName("");
            setCarModels("01"); // Reset to default model
            setSelectedBrand(""); // Reset selected brand
            setBrandZone(""); // Reset brandzone dropdown
            iziToast.success({
                title: "Success",
                message: "Car brand/model added successfully.",
                position: "topCenter",
            });
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                iziToast.error({
                    title: "Error",
                    message: error.response.data.message,
                    position: "topCenter",
                });
            } else {
                iziToast.error({
                    title: "Error",
                    message: "An error occurred while adding the car brand or model.",
                    position: "topCenter",
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const handleShow = (brand) => {
        setBrandToDelete(brand._id);
        setShow(true);
    };

    const handleClose = () => {
        setBrandToDelete(null);
        setShow(false);
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`${apipath}/carlist/delete/${brandToDelete}`);
            setBrands(brands.filter(brand => brand._id !== brandToDelete));
            handleClose();
        } catch (error) {
            iziToast.error({
                title: "Error",
                message: "Failed to delete model. Please try again later.",
                position: "topCenter",
            });
        }
    };

    return (
        <>
            <SideBar />

            <section className="home">
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className="bi bi-menu"></i>
                </div>
                <div className="container pt-3">
                    <div className="row top-barcolumn">
                        <div className='test-div'>
                            <h5>Add Car Brand</h5>
                        </div>

                        <Modal show={show} onHide={handleClose} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Brand</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="modal-content1">
                                    <p>Are you sure you want to delete this brand?</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="modal-footer-btn" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button className="modal-footer-btn" onClick={handleDelete}>
                                    Delete
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <div className="row nav-column justify-content-center">
                            <form className="row" onSubmit={handleAddBrandOrModel}>
                                <div className="col-lg-5">
                                    {/* Dropdown for brandzone */}
                                    <select
                                        className="form-control"
                                        value={brandZone}
                                        onChange={(e) => setBrandZone(e.target.value)}
                                        required
                                    >
                                        <option value="" disabled>Select Brand Zone</option>
                                        <option value="Korean Car">Korean Car</option>
                                        <option value="Japanese Car">Japanese Car</option>
                                        <option value="German Car">German Car</option>
                                        <option value="American Car">American Car</option>
                                        <option value="European Car">European Car</option>
                                        <option value="Chinese Car">Chinese Car</option>
                                    </select>
                                </div>

                                <div className="col-lg-5">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="brand-name"
                                        value={brandName}
                                        onChange={(e) => {
                                            setBrandName(e.target.value);
                                            setSelectedBrand(""); // Clear selected brand if entering a new brand
                                        }}
                                        placeholder="Car Brand"
                                        required
                                    />
                                </div>

                                <div className="col-lg-2">
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                        disabled={loading}
                                    >
                                        {loading ? "Adding..." : "Add Brand"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Add a list of all brands and models */}
                <div className="row nav-column container">
                    <div className="foot-tablerow">
                        <h5 className="ms-1">Brand List</h5>
                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <table id="datatable" className="table" ref={dataTableRef}>
                                    <thead>
                                        <tr>
                                            <th className="text-center col-2">S.No.</th>
                                            <th className="text-center col-4">Brand Zone</th>
                                            <th className="text-center col-4">Car Brand</th>
                                            <th className="text-center col-2">Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {brands.map((brand, index) => (
                                            <tr key={brand._id}>
                                                <td className="text-center">{index + 1}</td>
                                                <td className="text-center">{brand.brandzone}</td>
                                                <td className="text-center">{brand.brand}</td>
                                                <td className="d-flex align-items-center justify-content-center">
                                                    <i className="fa-solid fa-trash" onClick={() => handleShow(brand)}></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CarBrand;
