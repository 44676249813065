import React, { useState } from "react";
import SideBar from "./SideBar";

const DetailsEdit = () => {

    const [imageUrl, setImageUrl] = useState(null);

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImageUrl(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    };
  return (
    <>
      <SideBar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit Details Information</h5>
            <div className="col-lg-5 nav-column">
              <div>
                <form>
                  <div className="mb-3">
                    <label for="nav-items1" className="form-label">
                      Details
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Details"
                    />
                  </div>
                  <div className="mb-3">
                  <label for="nav-items2" className="form-label">
                      Details Image
                    </label>
                    <input
                    type="file"
                    onchange={handleFileChange}
                    className="pt-3"
                  />
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt="Preview"
                      style={{ width: "100px", height: "100px" }}
                    />
                    )}
                   </div>
                  <button type="submit" className="btn btn-success">
                  Update
                </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailsEdit;
