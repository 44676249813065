import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import Navbar from './Navbar';
import axios from 'axios';
import iziToast from "izitoast";
import { useAuth } from '../AuthContext';

const QuestionnariesList = () => {
    const [options, setOptions] = useState([]);
    const [optionId, setOptionId] = useState();
    const dataTableRef = useRef();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [newOption, setNewOption] = useState("");
    const [optionToDelete, setOptionToDelete] = useState(null);
    const { apipath } = useAuth();
    const navigate = useNavigate();

    const fetchQuestionnaires = async () => {
        try {
            const response = await axios.get(`${apipath}/ques/getall-questionnaires`);
            setOptions(response.data.data);
        } catch (error) {
            iziToast.error({
                title: 'Error',
                message: 'An error occurred while fetching data',
                position: 'topCenter'
            });
        }
    };

    useEffect(() => {
        fetchQuestionnaires();
    }, [apipath]);

    useEffect(() => {
        if (options.length > 0) {
            const table = $(dataTableRef.current).DataTable();
            table.destroy(); // Destroy existing instance

            $(dataTableRef.current).DataTable({
                paging: true,
                searching: true,
                responsive: true,
            });
        }
    }, [options]);

    const handleAddOption = async () => {
        if (!newOption.trim()) {
            iziToast.error({
                title: 'Error',
                message: 'Option name cannot be empty',
                position: 'topCenter',
            });
            return;
        }

        try {
            const response = await axios.post(`${apipath}/ques/create-questionnaires`, {
                optionName: newOption,
            });
            if (response.data.success) {
                iziToast.success({
                    title: 'Success',
                    message: 'Option added successfully',
                    position: 'topCenter',
                });
                setNewOption("");
                setShowAddModal(false);
                fetchQuestionnaires();
            } else {
                iziToast.error({
                    title: 'Error',
                    message: response.data.error || 'Failed to add option',
                    position: 'topCenter',
                });
            }
        } catch (error) {
            iziToast.error({
                title: 'Error',
                message: 'An error occurred while adding the option',
                position: 'topCenter',
            });
        }
    };

    const handleDeleteOption = async () => {
        try { 
            const response = await axios.delete(`${apipath}/ques/delete-questionnaires/${optionToDelete}`);
            if (response.data.success) {
                iziToast.success({
                    title: 'Success',
                    message: 'Option deleted successfully',
                    position: 'topCenter',
                });
                setShowDeleteModal(false);
                fetchQuestionnaires();
            } else {
                iziToast.error({
                    title: 'Error',
                    message: response.data.error || 'Failed to delete option',
                    position: 'topCenter',
                });
            }
        } catch (error) {
            iziToast.error({
                title: 'Error',
                message: 'An error occurred while deleting the option',
                position: 'topCenter',
            });
        }
    };

    const handleEdit = (option) => {
        navigate(`/troubleshoot-questionnaries-edit/${option._id}`)
    };

    return (
        <>
            <SideBar />
            <Navbar />

            <section className='home'>
                <div className="toggle-sidebar" style={{ display: "none" }}>
                    <i className='bi bi-menu'></i>
                </div>

                {/* Add Troubleshoot Modal */}
                <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Troubleshoot</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Troubleshooting Category"
                            value={newOption}
                            onChange={(e) => setNewOption(e.target.value)}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={() => setShowAddModal(false)}>Cancel</button>
                        <button className="btn btn-primary" onClick={handleAddOption}>Add</button>
                    </Modal.Footer>
                </Modal>

                {/* Delete Confirmation Modal */}
                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this troubleshoot?
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={() => setShowDeleteModal(false)}>Cancel</button>
                        <button className="btn btn-danger" onClick={handleDeleteOption}>Delete</button>
                    </Modal.Footer>
                </Modal>

                <div className="container">
                    <div className="row foot-tablerow">
                        <div className='test-div'>
                            <h5>Questionnaries for Car Maintenance</h5>
                            <button className='g-test' onClick={() => setShowAddModal(true)}>Add Troubleshoot</button>
                        </div>
                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <table id="datatable" className="table" ref={dataTableRef}>
                                    <thead>
                                        <tr>
                                            <th className='col-2 text-center'>S. No.</th>
                                            <th className='col-7 text-center'>Troubleshooting Category</th>
                                            <th className='col-3 text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {options.length > 0 ? (
                                            options.map((option, index) => (
                                                <tr key={option.id}>
                                                    <td className='col-2 text-center'>{index + 1}</td>
                                                    <td className='col-7 text-center'>{option.optionName}</td>
                                                    <td className='col-3 text-center'>
                                                        <i
                                                            className="fa-regular fa-pen-to-square"
                                                            onClick={() => {
                                                                handleEdit(option);
                                                            }}
                                                        ></i>&nbsp;
                                                        <i
                                                            className="fa-solid fa-trash"
                                                            onClick={() => {
                                                                setOptionToDelete(option._id);
                                                                setShowDeleteModal(true);
                                                            }}
                                                            style={{ cursor: 'pointer', color: 'red' }}
                                                        ></i>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3" className="text-center">
                                                    No options available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default QuestionnariesList;